var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12 col-lg-12" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "화학자재 기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && _vm.isOld
                            ? _c("c-btn", {
                                attrs: {
                                  label: "경고표지(6 x 4)",
                                  icon: "print",
                                },
                                on: {
                                  btnClicked: function ($event) {
                                    return _vm.printWarning("s")
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.isOld
                            ? _c("c-btn", {
                                attrs: {
                                  label: "경고표지(11 x 9)",
                                  icon: "print",
                                },
                                on: {
                                  btnClicked: function ($event) {
                                    return _vm.printWarning("m")
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.editable && !_vm.isOld
                            ? _c("c-btn", {
                                attrs: {
                                  label: "LBLLOAD",
                                  icon: "file_download",
                                },
                                on: { btnClicked: _vm.openReview },
                              })
                            : _vm._e(),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.chemData,
                                  mappingType: _vm.mappingType,
                                  label: "LBLSAVE",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveInfo,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-material", {
                          attrs: {
                            required: true,
                            editable: _vm.editable && _vm.isUpdate,
                            label: "자재관리번호/자재명",
                            name: "materialCd",
                            data: _vm.chemData,
                            type: "codename",
                            plantCd: _vm.chemData.plantCd,
                          },
                          on: {
                            "update:data": function ($event) {
                              _vm.chemData = $event
                            },
                            datachange: _vm.datachange,
                          },
                          model: {
                            value: _vm.chemData.materialCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "materialCd", $$v)
                            },
                            expression: "chemData.materialCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: false,
                            label: "화학자재일련번호",
                            name: "mdmChemMaterialId",
                          },
                          model: {
                            value: _vm.chemData.mdmChemMaterialId,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "mdmChemMaterialId", $$v)
                            },
                            expression: "chemData.mdmChemMaterialId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.chemData.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "plantCd", $$v)
                            },
                            expression: "chemData.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-vendor", {
                          attrs: {
                            editable: _vm.editable,
                            label: "공급업체",
                            name: "deliveryVendorCd",
                          },
                          model: {
                            value: _vm.chemData.deliveryVendorCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "deliveryVendorCd", $$v)
                            },
                            expression: "chemData.deliveryVendorCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "PROPERTIES_STATE_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "propertiesStateCd",
                            label: "성상",
                          },
                          model: {
                            value: _vm.chemData.propertiesStateCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "propertiesStateCd", $$v)
                            },
                            expression: "chemData.propertiesStateCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "비산성(고체)",
                            name: "fugacity",
                          },
                          model: {
                            value: _vm.chemData.fugacity,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "fugacity", $$v)
                            },
                            expression: "chemData.fugacity",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            type: "number",
                            label: "끓는점(액체,기체)",
                            name: "boilPoint",
                          },
                          model: {
                            value: _vm.chemData.boilPoint,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "boilPoint", $$v)
                            },
                            expression: "chemData.boilPoint",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            type: "number",
                            label: "녹는점",
                            name: "meltingPoint",
                          },
                          model: {
                            value: _vm.chemData.meltingPoint,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "meltingPoint", $$v)
                            },
                            expression: "chemData.meltingPoint",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "USAGE_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "usageCd",
                            label: "용도",
                          },
                          model: {
                            value: _vm.chemData.usageCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "usageCd", $$v)
                            },
                            expression: "chemData.usageCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable:
                              _vm.editable &&
                              _vm.chemData.usageCd == "UC99999999",
                            label: "용도(기타)",
                            name: "usageEtc",
                          },
                          model: {
                            value: _vm.chemData.usageEtc,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "usageEtc", $$v)
                            },
                            expression: "chemData.usageEtc",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            comboItems: _vm.mixFlagItems,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "mixFlag",
                            label: "제품구성",
                          },
                          model: {
                            value: _vm.chemData.mixFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "mixFlag", $$v)
                            },
                            expression: "chemData.mixFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "CAS No.",
                            name: "casNo",
                          },
                          model: {
                            value: _vm.chemData.casNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "casNo", $$v)
                            },
                            expression: "chemData.casNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "투입 용해조",
                            name: "inputDissolutionTank",
                          },
                          model: {
                            value: _vm.chemData.inputDissolutionTank,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.chemData,
                                "inputDissolutionTank",
                                $$v
                              )
                            },
                            expression: "chemData.inputDissolutionTank",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "PRODUCT_MANUF_CLASS_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            label: "제품제조구분",
                            name: "productManufClassCd",
                          },
                          model: {
                            value: _vm.chemData.productManufClassCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "productManufClassCd", $$v)
                            },
                            expression: "chemData.productManufClassCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "PRODUCT_CATEGORY_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            label: "제품구분",
                            name: "productCategoryCd",
                          },
                          model: {
                            value: _vm.chemData.productCategoryCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "productCategoryCd", $$v)
                            },
                            expression: "chemData.productCategoryCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "CHEM_INGREDIENTS_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            label: "구성성분 작성근거",
                            name: "chemIngredientsCd",
                          },
                          model: {
                            value: _vm.chemData.chemIngredientsCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "chemIngredientsCd", $$v)
                            },
                            expression: "chemData.chemIngredientsCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            rows: 2,
                            label: "비고",
                            name: "remarks",
                          },
                          model: {
                            value: _vm.chemData.remarks,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "remarks", $$v)
                            },
                            expression: "chemData.remarks",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-6 col-lg-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { noHeader: true },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "보관시설(창고) 유무",
                            name: "keepFacilityFlag",
                          },
                          model: {
                            value: _vm.chemData.keepFacilityFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "keepFacilityFlag", $$v)
                            },
                            expression: "chemData.keepFacilityFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            disabled: _vm.chemData.keepFacilityFlag === "N",
                            editable: _vm.editable,
                            type: "number",
                            label: "최대보관량(톤)",
                            name: "maxKeepAmount",
                          },
                          model: {
                            value: _vm.chemData.maxKeepAmount,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "maxKeepAmount", $$v)
                            },
                            expression: "chemData.maxKeepAmount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "저장시설(탱크) 유무",
                            name: "storageFacilityFlag",
                          },
                          model: {
                            value: _vm.chemData.storageFacilityFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "storageFacilityFlag", $$v)
                            },
                            expression: "chemData.storageFacilityFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            disabled: _vm.chemData.storageFacilityFlag === "N",
                            editable: _vm.editable,
                            type: "number",
                            label: "최대저장량(톤)",
                            name: "maxStorageAmount",
                          },
                          model: {
                            value: _vm.chemData.maxStorageAmount,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "maxStorageAmount", $$v)
                            },
                            expression: "chemData.maxStorageAmount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "제조·사용시설 여부",
                            name: "manufacturingFacilitiesFlag",
                          },
                          model: {
                            value: _vm.chemData.manufacturingFacilitiesFlag,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.chemData,
                                "manufacturingFacilitiesFlag",
                                $$v
                              )
                            },
                            expression: "chemData.manufacturingFacilitiesFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-9 col-lg-9" },
                      [
                        _c("font", { staticClass: "formLabelTitle txtlabel" }, [
                          _vm._v("나노물질"),
                        ]),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "제조",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.nanoManufacturingFlag,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.chemData,
                                "nanoManufacturingFlag",
                                $$v
                              )
                            },
                            expression: "chemData.nanoManufacturingFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "수입",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.nanoIncomeFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "nanoIncomeFlag", $$v)
                            },
                            expression: "chemData.nanoIncomeFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "해당없음",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.nanoNoneApplicableFlag,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.chemData,
                                "nanoNoneApplicableFlag",
                                $$v
                              )
                            },
                            expression: "chemData.nanoNoneApplicableFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "모름",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.nanoUnknownFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "nanoUnknownFlag", $$v)
                            },
                            expression: "chemData.nanoUnknownFlag",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-6 col-lg-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { noHeader: true },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "인허가대상 물질 여부",
                            name: "licensingFlag",
                          },
                          model: {
                            value: _vm.chemData.licensingFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "licensingFlag", $$v)
                            },
                            expression: "chemData.licensingFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "위험물 여부",
                            name: "dangerFlag",
                          },
                          model: {
                            value: _vm.chemData.dangerFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "dangerFlag", $$v)
                            },
                            expression: "chemData.dangerFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "LBLUSEFLAG",
                            name: "useFlag",
                          },
                          model: {
                            value: _vm.chemData.useFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "useFlag", $$v)
                            },
                            expression: "chemData.useFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "유해화학물질(PSM) 여부",
                            name: "psmFlag",
                          },
                          model: {
                            value: _vm.chemData.psmFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "psmFlag", $$v)
                            },
                            expression: "chemData.psmFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-9 col-lg-9" },
                      [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c(
                              "font",
                              { staticClass: "formLabelTitle txtlabel" },
                              [_vm._v("제품 분류 ")]
                            ),
                            _c("q-checkbox", {
                              staticClass: "customqcbox",
                              attrs: {
                                editable: _vm.editable,
                                dense: "",
                                color: "orange-custom",
                                label: "제조",
                                "true-value": "Y",
                                "false-value": "N",
                              },
                              model: {
                                value: _vm.chemData.makeFlag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.chemData, "makeFlag", $$v)
                                },
                                expression: "chemData.makeFlag",
                              },
                            }),
                            _c("q-checkbox", {
                              staticClass: "customqcbox",
                              attrs: {
                                editable: _vm.editable,
                                dense: "",
                                color: "orange-custom",
                                label: "수입",
                                "true-value": "Y",
                                "false-value": "N",
                              },
                              model: {
                                value: _vm.chemData.impFlag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.chemData, "impFlag", $$v)
                                },
                                expression: "chemData.impFlag",
                              },
                            }),
                            _c("q-checkbox", {
                              staticClass: "customqcbox",
                              attrs: {
                                editable: _vm.editable,
                                dense: "",
                                color: "orange-custom",
                                label: "구매",
                                "true-value": "Y",
                                "false-value": "N",
                              },
                              model: {
                                value: _vm.chemData.buyFlag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.chemData, "buyFlag", $$v)
                                },
                                expression: "chemData.buyFlag",
                              },
                            }),
                            _c("q-checkbox", {
                              staticClass: "customqcbox",
                              attrs: {
                                editable: _vm.editable,
                                dense: "",
                                color: "orange-custom",
                                label: "사용",
                                "true-value": "Y",
                                "false-value": "N",
                              },
                              model: {
                                value: _vm.chemData.usingFlag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.chemData, "usingFlag", $$v)
                                },
                                expression: "chemData.usingFlag",
                              },
                            }),
                            _c("q-checkbox", {
                              staticClass: "customqcbox",
                              attrs: {
                                editable: _vm.editable,
                                dense: "",
                                color: "orange-custom",
                                label: "수출",
                                "true-value": "Y",
                                "false-value": "N",
                              },
                              model: {
                                value: _vm.chemData.expFlag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.chemData, "expFlag", $$v)
                                },
                                expression: "chemData.expFlag",
                              },
                            }),
                            _c("q-checkbox", {
                              staticClass: "customqcbox",
                              attrs: {
                                editable: _vm.editable,
                                dense: "",
                                color: "orange-custom",
                                label: "판매",
                                "true-value": "Y",
                                "false-value": "N",
                              },
                              model: {
                                value: _vm.chemData.salesFlag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.chemData, "salesFlag", $$v)
                                },
                                expression: "chemData.salesFlag",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c(
                              "font",
                              { staticClass: "formLabelTitle txtlabel" },
                              [_vm._v("유해화학물질 포함 여부 ")]
                            ),
                            _c("q-checkbox", {
                              staticClass: "customqcbox",
                              attrs: {
                                editable: _vm.editable,
                                dense: "",
                                color: "orange-custom",
                                label: "유독물질",
                                "true-value": "Y",
                                "false-value": "N",
                              },
                              model: {
                                value: _vm.chemData.toxicPoisonFlag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.chemData, "toxicPoisonFlag", $$v)
                                },
                                expression: "chemData.toxicPoisonFlag",
                              },
                            }),
                            _c("q-checkbox", {
                              staticClass: "customqcbox",
                              attrs: {
                                editable: _vm.editable,
                                dense: "",
                                color: "orange-custom",
                                label: "허가물질",
                                "true-value": "Y",
                                "false-value": "N",
                              },
                              model: {
                                value: _vm.chemData.toxicPermitFlag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.chemData, "toxicPermitFlag", $$v)
                                },
                                expression: "chemData.toxicPermitFlag",
                              },
                            }),
                            _c("q-checkbox", {
                              staticClass: "customqcbox",
                              attrs: {
                                editable: _vm.editable,
                                dense: "",
                                color: "orange-custom",
                                label: "제한물질",
                                "true-value": "Y",
                                "false-value": "N",
                              },
                              model: {
                                value: _vm.chemData.toxicLimitFlag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.chemData, "toxicLimitFlag", $$v)
                                },
                                expression: "chemData.toxicLimitFlag",
                              },
                            }),
                            _c("q-checkbox", {
                              staticClass: "customqcbox",
                              attrs: {
                                editable: _vm.editable,
                                dense: "",
                                color: "orange-custom",
                                label: "금지물질",
                                "true-value": "Y",
                                "false-value": "N",
                              },
                              model: {
                                value: _vm.chemData.toxicProhibitFlag,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.chemData,
                                    "toxicProhibitFlag",
                                    $$v
                                  )
                                },
                                expression: "chemData.toxicProhibitFlag",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }