<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <c-card title="화학자재 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable&&isOld"
                  label="경고표지(6 x 4)"
                  icon="print"
                  @btnClicked="printWarning('s')" />
                <c-btn
                  v-if="editable&&isOld"
                  label="경고표지(11 x 9)"
                  icon="print"
                  @btnClicked="printWarning('m')" />
                <c-btn
                  v-if="editable&&!isOld"
                  label="LBLLOAD"
                  icon="file_download"
                  @btnClicked="openReview" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="chemData"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <c-material
                  :required="true"
                  :editable="editable && isUpdate"
                  label="자재관리번호/자재명"
                  name="materialCd"
                  :data.sync="chemData"
                  type="codename"
                  :plantCd="chemData.plantCd"
                  v-model="chemData.materialCd"
                  @datachange="datachange">
                </c-material>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-text
                  :editable="false"
                  label="화학자재일련번호"
                  name="mdmChemMaterialId"
                  v-model="chemData.mdmChemMaterialId">
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-plant
                  :required="true"
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="chemData.plantCd" />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-vendor
                  :editable="editable"
                  label="공급업체"
                  name="deliveryVendorCd"
                  v-model="chemData.deliveryVendorCd">
                </c-vendor>
              </div>              
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-select
                  :editable="editable"
                  codeGroupCd="PROPERTIES_STATE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="propertiesStateCd"
                  label="성상"
                  v-model="chemData.propertiesStateCd"
                ></c-select>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-text
                  :editable="editable"
                  label="비산성(고체)"
                  name="fugacity"
                  v-model="chemData.fugacity">
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-text
                  :editable="editable"
                  type="number"
                  label="끓는점(액체,기체)"
                  name="boilPoint"
                  v-model="chemData.boilPoint">
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-text
                  :editable="editable"
                  type="number"
                  label="녹는점"
                  name="meltingPoint"
                  v-model="chemData.meltingPoint">
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-select
                  :editable="editable"
                  codeGroupCd="USAGE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="usageCd"
                  label="용도"
                  v-model="chemData.usageCd"
                ></c-select>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-text
                  :editable="editable && chemData.usageCd=='UC99999999'"
                  label="용도(기타)"
                  name="usageEtc"
                  v-model="chemData.usageEtc">
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-select
                  :editable="editable"
                  :comboItems="mixFlagItems"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="mixFlag"
                  label="제품구성"
                  v-model="chemData.mixFlag"
                ></c-select>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-text
                  :editable="editable"
                  label="CAS No."
                  name="casNo"
                  v-model="chemData.casNo">
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-text
                  :editable="editable"
                  label="투입 용해조"
                  name="inputDissolutionTank"
                  v-model="chemData.inputDissolutionTank">
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-select
                  :editable="editable"
                  codeGroupCd="PRODUCT_MANUF_CLASS_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  label="제품제조구분"
                  name="productManufClassCd"
                  v-model="chemData.productManufClassCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <c-select
                  :editable="editable"
                  codeGroupCd="PRODUCT_CATEGORY_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  label="제품구분"
                  name="productCategoryCd"
                  v-model="chemData.productCategoryCd"
                ></c-select>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                <c-select
                  :editable="editable"
                  codeGroupCd="CHEM_INGREDIENTS_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  label="구성성분 작성근거"
                  name="chemIngredientsCd"
                  v-model="chemData.chemIngredientsCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-textarea
                  :editable="editable"
                  :rows="2"
                  label="비고"
                  name="remarks"
                  v-model="chemData.remarks">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-6 col-lg-6">
          <c-card class="cardClassDetailForm" :noHeader="true">
            <template slot="card-detail">
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="보관시설(창고) 유무"
                  name="keepFacilityFlag"
                  v-model="chemData.keepFacilityFlag"
                />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                <c-text
                  :disabled="chemData.keepFacilityFlag==='N'"
                  :editable="editable"
                  type="number"
                  label="최대보관량(톤)"
                  name="maxKeepAmount"
                  v-model="chemData.maxKeepAmount"
                />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="저장시설(탱크) 유무"
                  name="storageFacilityFlag"
                  v-model="chemData.storageFacilityFlag"
                />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                <c-text
                  :disabled="chemData.storageFacilityFlag==='N'"
                  :editable="editable"
                  type="number"
                  label="최대저장량(톤)"
                  name="maxStorageAmount"
                  v-model="chemData.maxStorageAmount"
                />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="제조·사용시설 여부"
                  name="manufacturingFacilitiesFlag"
                  v-model="chemData.manufacturingFacilitiesFlag"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                <font class="formLabelTitle txtlabel">나노물질</font>
                <q-checkbox
                  :editable="editable"
                  v-model="chemData.nanoManufacturingFlag"
                  dense
                  color="orange-custom"
                  label="제조"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  :editable="editable"
                  v-model="chemData.nanoIncomeFlag"
                  dense
                  color="orange-custom"
                  label="수입"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  :editable="editable"
                  v-model="chemData.nanoNoneApplicableFlag"
                  dense
                  color="orange-custom"
                  label="해당없음"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  :editable="editable"
                  v-model="chemData.nanoUnknownFlag"
                  dense
                  color="orange-custom"
                  label="모름"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-6 col-lg-6">
          <c-card class="cardClassDetailForm" :noHeader="true">
            <template slot="card-detail">
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="인허가대상 물질 여부"
                  name="licensingFlag"
                  v-model="chemData.licensingFlag"
                />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="위험물 여부"
                  name="dangerFlag"
                  v-model="chemData.dangerFlag"
                />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="LBLUSEFLAG"
                  name="useFlag"
                  v-model="chemData.useFlag"
                />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="유해화학물질(PSM) 여부"
                  name="psmFlag"
                  v-model="chemData.psmFlag"
                />
              </div>
              <!-- <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  name="ghsFlag"
                  label="GHS분류 대상"
                  v-model="chemData.ghsFlag"
                />
              </div> -->
              <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                <div class="col-12">
                  <font class="formLabelTitle txtlabel">제품 분류 </font>
                  <q-checkbox
                    :editable="editable"
                    v-model="chemData.makeFlag"
                    dense
                    color="orange-custom"
                    label="제조"
                    true-value="Y"
                    false-value="N"
                    class="customqcbox"
                  />
                  <q-checkbox
                    :editable="editable"
                    v-model="chemData.impFlag"
                    dense
                    color="orange-custom"
                    label="수입"
                    true-value="Y"
                    false-value="N"
                    class="customqcbox"
                  />
                  <q-checkbox
                    :editable="editable"
                    v-model="chemData.buyFlag"
                    dense
                    color="orange-custom"
                    label="구매"
                    true-value="Y"
                    false-value="N"
                    class="customqcbox"
                  />
                  <q-checkbox
                    :editable="editable"
                    v-model="chemData.usingFlag"
                    dense
                    color="orange-custom"
                    label="사용"
                    true-value="Y"
                    false-value="N"
                    class="customqcbox"
                  />
                  <q-checkbox
                    :editable="editable"
                    v-model="chemData.expFlag"
                    dense
                    color="orange-custom"
                    label="수출"
                    true-value="Y"
                    false-value="N"
                    class="customqcbox"
                  />
                  <q-checkbox
                    :editable="editable"
                    v-model="chemData.salesFlag"
                    dense
                    color="orange-custom"
                    label="판매"
                    true-value="Y"
                    false-value="N"
                    class="customqcbox"
                  />
                </div>
                <div class="col-12">
                  <font class="formLabelTitle txtlabel">유해화학물질 포함 여부 </font>
                  <q-checkbox
                    :editable="editable"
                    v-model="chemData.toxicPoisonFlag"
                    dense
                    color="orange-custom"
                    label="유독물질"
                    true-value="Y"
                    false-value="N"
                    class="customqcbox"
                  />
                  <q-checkbox
                    :editable="editable"
                    v-model="chemData.toxicPermitFlag"
                    dense
                    color="orange-custom"
                    label="허가물질"
                    true-value="Y"
                    false-value="N"
                    class="customqcbox"
                  />
                  <q-checkbox
                    :editable="editable"
                    v-model="chemData.toxicLimitFlag"
                    dense
                    color="orange-custom"
                    label="제한물질"
                    true-value="Y"
                    false-value="N"
                    class="customqcbox"
                  />
                  <q-checkbox
                    :editable="editable"
                    v-model="chemData.toxicProhibitFlag"
                    dense
                    color="orange-custom"
                    label="금지물질"
                    true-value="Y"
                    false-value="N"
                    class="customqcbox"
                  />
                </div>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chem-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        subtabName: '',
        plantCd: null,
      }),
    },
    chemData: {
      type: Object,
      default: () => ({
        plantCd: null,  // 사업장코드
        mdmChemMaterialId: '',  // 화학자재 일련번호
        materialCd: '',  // 자재코드
        mdmChemReviewId: '',  // 사전검토 일련번호
        deliveryVendorCd: '',  // 공급업체 코드
        // mfgVendorCd: '',  // (사용안함) 제조업체 코드
        propertiesStateCd: null,  // 성상-공통코드
        fugacity: '',  // 비산성(고체)
        boilPoint: '',  // 끊는점(액체,기체)
        meltingPoint: '',  // 녹는점
        moleWeight: '',  // 분자량
        usageCd: null,  // 용도-회사별 공통코드
        usageEtc: '',  // 용도 기타
        mixFlag: '',  // 물질구성(단일 N/혼합 Y)
        casNo: '',  // CAS No.
        // hsNo: '',  // (사용안함) HS번호
        // specificGravity: '',  // (사용안함) 비중
        // serialNo: '',  // (사용안함) 고유번호(유독물번호or고시번호)
        // keNo: '',  // (사용안함) KE 번호
        // storagePlace: '',  // (사용안함) 보관장소
        productCategoryCd: null,  // 제품구분
        keepFacilityFlag: 'N',  // 보관시설 유무
        maxKeepAmount: '',  // 최대보관량
        storageFacilityFlag: 'N',  // 저장시설 유무
        maxStorageAmount: '',  // 최대저장량
        manufacturingFacilitiesFlag: 'N',  // 제조 사용시설 여부
        nanoManufacturingFlag: 'N',  // 나노물질 제조 여부
        nanoIncomeFlag: 'N',  // 나노물질 수입 여부
        nanoNoneApplicableFlag: 'N',  // 나노물질 해당없음 여부
        nanoUnknownFlag: 'N',  // 나노물질 모름 여부
        inputDissolutionTank: '',  // 투입 용해조
        productManufClassCd: null,  // 제품제조구분 코드
        chemIngredientsCd: '',  // 구성성분 작성근거
        useFlag: 'Y',  // 사용여부
        licensingFlag: 'N',  // 인허가대상 물질 여부 Y/N
        dangerFlag: 'N',  // 위험물 여부Y/N
        psmFlag: 'N',  // PSM 대상 여부
        makeFlag: 'N',  // 제품분류(제조)
        impFlag: 'N',  // 제품분류(수입)
        buyFlag: 'N',  // 제품분류(구매)
        usingFlag: 'N',  // 제품분류(사용)
        expFlag: 'N',  // 제품분류(수출)
        salesFlag: 'N',  // 제품분류(판매)
        toxicPoisonFlag: 'N',  // 유해화학물질포함여부(유독물질)
        toxicPermitFlag: 'N',  // 유해화학물질포함여부(허가물질)
        toxicLimitFlag: 'N',  // 유해화학물질포함여부(제한물질)
        toxicProhibitFlag: 'N',  // 유해화학물질포함여부(금지물질)
        remarks: '',  // 비고
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        // specialCtype: '',  // (사용안함) 발암성 C-위험성평가용
        // specialRtype: '',  // (사용안함) 생식독성 R-위험성평가용
        // specialMtype: '',  // (사용안함) 생식세표변이원성 M-위험성평가용
        // rcode: '',  // (사용안함) 위험문구(R-phrase)-위험성평가용
        // hcode: '',  // (사용안함) 유해위험문구(H-code)-위험성평가용
        // cmrFlag: '',  // (사용안함) CMR물질여부-위험성평가용
        // boilingPoint: '',  // (사용안함) 끓는점-위험성평가용
        // hrCodeGrade: '',  // (사용안함) 위험문구/유해위험문구 등급-위험성평가용
        // maleficenceForm: '',  // (사용안함) 유해성 발생형태-위험성평가용
        // maleficenceValue: '',  // (사용안함) 유해성 측정값-위험성평가용
        // maleficenceGrade: '',  // (사용안함) 비산성 등급-위험성평가용
        // maleficenceType: '',  // (사용안함) 비산/휘발성 구분-위험성평가용
        // dayVolume: '',  // (사용안함) 하루취급량
        // dayVolumeUnit: '',  // (사용안함) 하루취급량 단위
        // ghsFlag: '',  // (사용안함) GHS 분류코드
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      isUpdate: true,
      saveUrl: '',
      mappingType: 'POST',
      printUrl: '',
      insertUrl: '',
      updateUrl: '',
      checkUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      savePsmFlag: '',
      mixFlagItems: [
        { code: 'Y', codeName: '혼합' },
        { code: 'N', codeName: '단일' },
      ],
      cmrFlagItems: [
        { code: '1A', codeName: '1A' },
        { code: '1B', codeName: '1B' },
        { code: '2', codeName: '2' },
      ],
      gradeItems: [
        { code: '4', codeName: '최대' },
        { code: '3', codeName: '대' },
        { code: '2', codeName: '중' },
        { code: '1', codeName: '소' },
      ],
      formItems: [
        { code: '분진', codeName: '분진(㎎/㎥)' },
        { code: '증기', codeName: '증기(ppm)' },
      ],
    };
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.mdmChemMaterialId)
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.mdm.mam.chem.insert.url;
      this.printUrl = selectConfig.mdm.mam.chem.print.url;
      this.saveUrl = transactionConfig.mdm.mam.chem.insert.url;
      this.checkUrl = selectConfig.mdm.mam.chem.check.url;
    },
    saveInfo() {
      if (this.popupParam.mdmChemMaterialId) {
        this.saveUrl = transactionConfig.mdm.mam.chem.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.mdm.mam.chem.insert.url;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          let message = 'MSGSAVE';
          if (this.savePsmFlag !== this.chemData.psmFlag && this.chemData.psmFlag !== 'Y' && !this.popupParam.mdmChemMaterialId) {
            message = '저장하시겠습니까?\n※ PSM 여부가 "No"로 변경되었습니다.\n  유해화학물질(PSM)정보는 지워집니다.'
          }
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: message,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.chemData.regUserId = this.$store.getters.user.userId;
              this.chemData.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType == 'POST') {
        this.$set(this.popupParam, 'mdmChemMaterialId', result.data.mdmChemMaterialId)
        this.$set(this.popupParam, 'plantCd', result.data.plantCd)
      }
      this.$emit('getDetail');
    },
    changeCMR() {
      if (this.chemData.specialCtype !== null || this.chemData.specialMtype !== null || this.chemData.specialRtype !== null) {
        this.chemData.cmrFlag = 'Y';
      } else {
        this.chemData.cmrFlag = 'N';
      }
    },
    openReview() {
      this.popupOptions.title = '사전검토 완료 정보 검색';
      this.popupOptions.param = {
        type: 'single'
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/mam/review/chemReviewPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeReviewPopup;
    },
    closeReviewPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (data[0].materialCd.indexOf('TEMP') === 0) {
          // 등록되지 않은 자재
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: `등록되지 않은 자재로 화학자재 저장시 자재마스터에 저장됩니다.
(자재코드 : ${this.$_.replace(data[0].materialCd, 'TEMP', '')})`,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.$_.extend(this.chemData, data[0])
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          // 등록된 자재
          if (data[0].materialCount > 0) {
            // 자재코드로 등록된 화학자재가 있는 경우
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: `해당 자재로 등록된 화학자재가 있습니다.
진행하시겠습니까?
(자재코드 : ${data[0].materialCd})`,
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.$_.extend(this.chemData, data[0])
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            // 자재코드로 등록된 화학자재가 없는 경우
            this.$_.extend(this.chemData, data[0])
          }
        }
      }
    },
    datachange() {
      /**
       * 자재 및 사전검토 일련번호 정보 초기화
       */
      this.$set(this.chemData, 'mdmChemReviewId', '')
      this.$set(this.chemData, 'materialName', '')
      this.$set(this.chemData, 'materialNameEn', '')
    },
    printWarning(size) {
      let thisVue = this;
      this.$http.url = this.$format(this.printUrl, this.popupParam.mdmChemMaterialId);
      this.$http.type = 'GET';
      this.$http.param = {
        size: size
      };
      this.$http.request(
        _result => {
          let sizeTitle = size === 's' ? '6 x 4' : '11 x 9';
          let fileOrgNm = this.chemData.materialName + '_' + sizeTitle + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    }
  }
};
</script>